<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab/>

    <div class="layout-filter-box">
      <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'게시판 분류'" :inputType="'radio'" @set="setMiniFilter"/>
    </div>

    <div class="w-960 mx-auto">
        
      <div class="sort-box">
        <div v-for="(item, index) in opt.sort" :key="`sort${index}`" :class="{'on' : item.value === sort}" @click="setSort(item.value)">{{ item.text }}</div>
      </div>

      <div class="community-list" v-if="list && list.length > 0">
        <template v-for="(item, index) in list">
          <div class="community-item">
            <div v-if="item.thumbnail" class="item-img">
              <div class="thumb-img">
                <image-alt :src="item.thumbnail" altType="list"/>
                <div class="item-state" v-if="item.saveStatus !== 1">
                  <div v-if="item.saveStatus == 0">심사중</div>
                  <div v-if="item.saveStatus == 9">임시저장</div>
                </div>
              </div>
            </div>
            <div class="item-con cursor-pointer" @click="goDetail(item)" :key="`post-${index}`">
              <div v-if="!item.blindYN && (item.saveStatus == 1 || item.saveStatus == 9)" class="edite-btn">
                <div @click.stop="edit(item.cateCode, item.boIdx)">수정</div>
                <div @click.stop="confirmRemovePopup(item.boIdx)">삭제</div>
              </div>
              <span class="color-1">[<span v-if="item.parentCateName">{{ item.parentCateName }}/</span>{{ item.categoryName }}]</span>
              <div class="item-title">
                <template v-if="item.blindYN">{{ $msg('ok.blinded') }}</template>
                <template v-else>{{ item.title }}</template>
              </div>
              <div class="info">
                <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span> · <span>댓글 {{item.commentCount}}ㆍ추천 {{item.likeCnt}}ㆍ조회 {{item.view}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="noDataMessage" style="border: 0">{{ $msg('nodata2') }}</div>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>

    <transition name="slide-up">
      <preview-popup v-if="isPreviewPopup" :boIdx="previewTarget" @close="closePreviewPopup"/>
    </transition>
  </div>
</template>
<script>
import listMixin from "@/mixins/listMixin"
import PreviewPopup from "@/components/board/PreviewPopup"
export default {
  name: "mypagePosts",
  mixins: [listMixin],
  components: {PreviewPopup},
  data() {
    return {
      isPreviewPopup: false,
      previewTarget: null,
    }
  },
  created() {
    this.setOptCateCode()
  },
  methods: {
    async setOptCateCode() {
      this.opt.cateCode = [
        { text: '전체', value: null },
        { text: '꼬리TIP', value: 10 },
        { text: '꼬뮤니티', value: 80 },
      ]
    },
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getMyBoardList({
          cateCode: this.cateCode,
          orderBy: this.sort,
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success' && list && list.length > 0) {
          this.list = this.page == 1 ? list : this.list.concat(list)
          setTimeout(() => {
            this.page ++
            $state.loaded()
          }, 1000)
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setMiniFilter(target, value) {
      this[target] = value
    },
    goDetail(item) {
      if (item.blindYN) {
        this.setMsgPopup({
          type: 'alert',
          message: this.$msg('ok.blinded'),
          okay: 'BLIND',
          okayCallback: () => {
            this.setMsgPopup()
          },
        })
      }
      else if (item.saveStatus != 1) {
        this.previewTarget = item.boIdx
        this.openPreviewPopup()
      }
      else {
        this.setBeforeContent()
        if (item.parentCategory === 10) this.$router.push(`/tip/${item.boIdx}`)
        if (item.parentCategory === 80) this.$router.push(`/ggomunity/${item.boIdx}`)
      }
    },
    edit(cateCode, id) {
      if (this.$isIE()) this.$router.push('/ie')
      if (cateCode.toString().startsWith('10')) this.$router.push(`/tip/${id}/modify`)
      if (cateCode.toString().startsWith('80')) this.$router.push(`/ggomunity/${id}/modify`)
    },
    async remove(id) {
      if (!id) return false
      const { result } = await this.$api.removeMyBoard({ boIdx: id })
      if (result === 'success') this.list = this.list.filter(l => l.boIdx != id)
    },
    confirmRemovePopup(id){
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.setMsgPopup()
          this.remove(id)
          this.setMsgPopup()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    openPreviewPopup() {
      document.body.style.overflowY ="hidden"
      this.isPreviewPopup = true
    },
    closePreviewPopup() {
      document.body.style.overflowY ="scroll"
      this.isPreviewPopup = false
    }
  }
}
</script>
