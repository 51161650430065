<template>
  <div class="preview-popup">
    <div class="preview-header">
      미리보기
      <div class="close" @click="close" >닫기</div>
    </div>
    <div class="preview-body">

      <div class="inner-layout-800">

        <div class="detailP-subject-box">
          <div class="category">{{ item.categoryName }} / {{ item.petName }}</div>
          <div class="heading-1">{{ item.title }}</div>
        </div>

        <div class="detailP-profile-lable-box">
          <profile :profileType="'detail'" :item="item"/>
        </div>

        <div class="detailP-thumb">
          <image-alt :src="item.src" altType="detail"/>
        </div>

        <div v-html="item.content" class="detailP-content"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "previewPopup",
  props: ['boIdx'],
  data() {
    return {
      item: {},
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    async setItem() {
      if (!this.boIdx) this.close()
      const { result, data } = await this.$api.getBoardDetail({
        cateCode: 10,
        boIdx: this.boIdx,
        isPreview: true
      })
      if (result === 'success') this.item = data
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>
